import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import AnimatedText from './AnimatedText';
import AnimatedImages from './AnimatedImages';
const Hero = ({smallCaps, wordsForAnimation, wordsForAnimation2, screenshots}) => {
  
  const [isClient, setClient] = useState(false);
  const [resized, setResized] = useState(false)

  const key = isClient ? "client" : "server";
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 })


    useEffect(() => {
      setClient(true)
      window.addEventListener('resize', setResized(!resized))

      return () => {window.removeEventListener('resize', setResized(!resized))}

    }, [])

    if ( !isClient ) return null;

    return(
      <div key={key}>
        {isTabletOrMobile ? 
            <div className="hero-bg h-auto pt-8">
            <div className="px-3 flex flex-col text-center justify-center md:justify-center w-screen h-auto md:pt-24">
              <div className="relative h-vw">
                <AnimatedImages key={key} screenshots={screenshots} mobile={true}/>
              </div>
              <div id="hero-text" className="w-full h-auto">
                <h2 className="mt-0">{smallCaps}</h2>
                <h1 className="text-2xl md:text-3xl align-middle my-2 mb-0">Build, Launch, and Scale 
                  <AnimatedText wordsForAnimation={wordsForAnimation} text={true} />
                  <AnimatedText wordsForAnimation={wordsForAnimation2} />
                </h1>
                <ul className="pt-4 md:pt-8 pb-8">  
                  <li className="inline-block mx-1 md:mx-2"><a href="https://apps.apple.com/us/app/zurly/id692165372" target="_blank" rel="noreferrer" ><button id="ios" className="ios-btn" /></a></li>
                  <li id="play" className="inline-block mx-1 md:mx-2"><a href="https://play.google.com/store/apps/details?id=com.zurly.app&hl=en_US" target="_blank" rel="noreferrer" ><button className="android-btn" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        :
          <div className="hero-bg h-auto pb-40 md:pb-64 lg:h-85 xl:pb-0 xl:h-screen">
            <div className="px-3 md:px-10 lg:px-16 xl:px-32 lg:pb-8 text-left justify-center w-screen flex flex-row flex-wrap">
              <div id="hero-text" className="md:w-2/5 xl:w-1/2 my-auto flex-shrink pt-48">
                <h2>{smallCaps}</h2>
                <h1 className="text-2xl xl:text-4xl align-middle">Build, Launch, and Scale 
                  <AnimatedText wordsForAnimation={wordsForAnimation} text={true} />
                  <AnimatedText wordsForAnimation={wordsForAnimation2} />
                </h1>
                <ul className="pt-8">  
                  <li className="inline-block mx-2"><a href="https://apps.apple.com/us/app/zurly/id692165372" target="_blank" rel="noreferrer"><button className="ios-btn" /></a></li>
                  <li className="inline-block mx-2"><a href="https://play.google.com/store/apps/details?id=com.zurly.app&hl=en_US" target="_blank" rel="noreferrer"><button className="android-btn" /></a></li>
                </ul>
              </div>
                <AnimatedImages key={key} screenshots={screenshots} mobile={false}/>
            </div>
          </div>
        }
      </div>
    )
}


export default Hero
