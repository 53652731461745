import React from 'react'
import Button from './Button.jsx'

const Organization = ({data}) => {
  return (
    
    <div className="flex flex-col container text-center">
        <h1 className="pt-20 z-1">{data.h1}</h1>
        <p className="pb-10">{data.subheader}</p>
            <div className="flex flex-row flex-wrap justify-evenly">
                <div className="organization">
                  <a href={data.feature1Link}>
                    <div className="flex flex-col justify-evenly h-full">
                      <div>{data.feature1}</div>
                      <div className="organization-small-text"><span>Learn More</span><span>&nbsp;&nbsp;&nbsp; ⮕</span></div>
                    </div>
                  </a>
                </div>
                <div className="organization">
                  <a href={data.feature2Link}>
                    <div className="flex flex-col justify-evenly h-full">
                      <div>{data.feature2}</div>
                      <div className="organization-small-text"><span>Learn More</span><span>&nbsp;&nbsp;&nbsp; ⮕</span></div>
                    </div>
                  </a>
                </div>
                <div  className="organization">
                  <a href={data.feature3Link}>
                    <div className="flex flex-col justify-evenly h-full">
                      <div>{data.feature3}</div>
                      <div className="organization-small-text"><span>Learn More</span><span>&nbsp;&nbsp;&nbsp; ⮕</span></div>
                    </div>
                  </a>
                </div>
            </div>
        <span className="pt-20 pb-32"><Button href={data.buttonLink}>{data.button}</Button></span>

    </div>

    )
  
  }
  
  export default Organization