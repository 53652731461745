import React from "react"

const YoutubeVideo = () => {
  return (
    <div className="max-w-screen-xl px-4 pt-32 mx-auto">
      <div className="video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/u_hnG5WXz38?controls=0"
        />
      </div>
    </div>
  )
}

export default YoutubeVideo
