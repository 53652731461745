import React from "react"
import Img from "gatsby-image"

const Logos = ({ logos, logoText }) => (
  <div className="flex flex-col w-full max-w-screen-xl px-6 pt-32 mx-auto overflow-hidden xl:px-8">
    <h3 className="text-center">{logoText}</h3>
    <div className="flex flex-col justify-center pt-12 mx-auto md:flex-row">
      {logos.map((logo, index) => (
        <Img
          key={`logo-${index}`}
          fluid={logo.localFile.childImageSharp.fluid}
          alt={logo.description}
          className="w-32 h-32 mx-3 lg:h-48 lg:w-48 md:mx-6 lg:mx-12"
        />
      ))}
    </div>
  </div>
)

export default Logos
