import React, { useRef, useEffect, useState } from "react";
import gsap, {Linear} from "gsap";

const AnimatedImages = ({screenshots, mobile}) => {
  const [timeline] = useState(gsap.timeline({paused: true, repeat: -1}))
  const tl =  useRef(null)

  useEffect(() => {

    const imagesToFade = gsap.utils.toArray(".hero-image-fade");
    const showDuration = 2;
    const transitionDuration = 0.5;
    gsap.set(imagesToFade, {autoAlpha:0})
    gsap.set(imagesToFade[0], {autoAlpha:1})
    for (let i = 1; i < imagesToFade.length; i++) {
      timeline.to(imagesToFade[i], {autoAlpha:1, duration: transitionDuration}, (showDuration + transitionDuration) * i - transitionDuration);
      timeline.to(imagesToFade[i-1], {autoAlpha:0, duration: 0.01}, (showDuration + transitionDuration) * i);
    }
    timeline.add("end", "+=" + showDuration);
    timeline.set(imagesToFade[0], {autoAlpha:1}, "end"); //the first image should be fully opaque/visible so that when we fade the last image out, the first one is revealed.
    timeline.to(imagesToFade[imagesToFade.length-1], {autoAlpha:0, duration: transitionDuration}, "end");



    tl.current = timeline


    tl.current.play();
    
    return() => tl.current.pause().kill(); 
  }, [timeline])
  

  const AnimImages = () => {
    return(
      mobile ? 
      <div className="relative h-vw">
        <img src={screenshots.backgroundImage.fluid.src} className="mx-auto w-full z-0 absolute bottom-0 md:inset-y-0 md:inset-x-0" alt=""/>
        <div id="hero-image" className="mx-auto w-full  h-vw  my-auto relative" >
          <img src={screenshots.frame.fluid.src} className="absolute bottom-0 md:inset-y-0  w-full h-auto z-20" alt={screenshots.frame.description} />
          <img src={screenshots.image1.fluid.src} className="absolute bottom-0 md:inset-y-0 w-full h-auto z-10 hero-image-fade opacity-0" alt="" />
          <img src={screenshots.image2.fluid.src} className="absolute bottom-0 md:inset-y-0 w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image2.description} />
          <img src={screenshots.image3.fluid.src} className="absolute bottom-0 md:inset-y-0 w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image3.description} />
          <img src={screenshots.image4.fluid.src} className="absolute bottom-0 md:inset-y-0 w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image4.description} />
          <img src={screenshots.image5.fluid.src} className="absolute bottom-0 md:inset-y-0 w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image5.description} />
        </div>
      </div>
      :
      <div id="hero-image" className="w-3/5 xl:w-1/2 pt-32 relative">
        <img src={screenshots.backgroundImage.fluid.src} className="absolute w-full h-auto z-0" alt={screenshots.backgroundImage.description} />
        <img src={screenshots.frame.fluid.src} className="absolute w-full h-auto z-20" alt={screenshots.frame.description} />
        <img src={screenshots.image1.fluid.src} className="absolute w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image1.description} />
        <img src={screenshots.image2.fluid.src} className="absolute w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image2.description} />
        <img src={screenshots.image3.fluid.src} className="absolute w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image3.description} />
        <img src={screenshots.image4.fluid.src} className="absolute w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image4.description} />
        <img src={screenshots.image5.fluid.src} className="absolute w-full h-auto z-10 hero-image-fade opacity-0" alt={screenshots.image5.description} />
      </div>
    )
  }

  return(
   <AnimImages />
  )
}

export default AnimatedImages;