import React from 'react'
import Img from 'gatsby-image'

import Button from "../components/Button"

const BannerReverse = ({headline, copy, cta_link, cta_title, sequence, image, image_title}) => {


  return(
    <div className="flex flex-col-reverse md:flex-row-reverse md:justify-evenly md:items-center py-2 md:my-1 max-w-full">
        <div className="md:w-3/5 lg:w-1/2 flex flex-col md:flex-row items-center text-center md:text-left max-w-full md:mx-6">
            <h1 className="md:self-start md:text-6xl text-btncolor md:my-0">{sequence}.</h1>
            <div className="md:ml-5 max-w-full">
                <h3 className="md:text-3xl truncate">{headline}</h3>
                <p className="my-6 w-5/6 mx-auto md:mx-0">{copy}</p>
                <Button href={cta_link}>{cta_title}</Button>
            </div>
        </div>
        <div className="w-full md:w-2/5 lg:w-1/2 max-w-xl mx-auto">
            <Img fluid={image} alt={image_title}/>
        </div>
    </div>
  )
}



export default BannerReverse

