import React from 'react'
import Button from './Button.jsx'
import BenefitsCard from './BenefitsCard'

const BenefitsWrap = ({title, buttonText, buttonLink, cards}) => {

  return (
    
    
    <div className="flex flex-col container text-center">
        <h1 className="pb-10 z-1">{title}</h1>
        <div className="relative w-full h-full">
            <div className="absolute min-w-xxs h-48 decorative-card-first"></div>
            <div className="absolute min-w-xxs h-48 decorative-card-last"></div>

            <div className="flex flex-row flex-wrap justify-center">
                { cards.map(card => (
                    <BenefitsCard title={card.featureTitle} 
                        icon={card.icon.localFile.childImageSharp.fluid} 
                        iconAlt={card.icon.description} 
                        benefitsList={card.featureBenefits}
                        key={card.featureTitle}
                    />
                ))}
            </div>
        </div>
        <span className="pt-20"><Button href={buttonLink}>{buttonText}</Button></span>

    </div>

    )
  
  }
  
  export default BenefitsWrap