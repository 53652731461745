import React, { useRef, useEffect } from "react";
import gsap, {Power1} from "gsap";

const AnimatedText = ({wordsForAnimation, text}) => {

  const tl =  useRef(gsap.timeline({paused: true, repeat: -1}))

  useEffect(() => {

    const wordForAnimOrg = gsap.utils.toArray(".animated-text-org")  
    const wordForAnimBenefit = gsap.utils.toArray(".animated-text-benefit")  
    if (wordForAnimOrg.length !== wordForAnimBenefit.length){
      console.log("WARNING: LENGTH OF ORGANIZATIONS DOES NOT MATCH LENGTH OF BENEFITS. FIX IN CONTENTFUL.")
    }

    gsap.set([wordForAnimOrg, wordForAnimBenefit], {autoAlpha: 0, display: "none"});
    gsap.set([wordForAnimOrg[0],wordForAnimBenefit[0]], {autoAlpha: 1, display: "inline-block"})


    wordForAnimOrg.forEach((word, index) => {
      tl.current.to([wordForAnimOrg[index], wordForAnimBenefit[index]], {autoAlpha: 1, display: "inline-block", duration: 1, ease: Power1.easeInOut})
      tl.current.to([wordForAnimOrg[index], wordForAnimBenefit[index]], {autoAlpha: 1, display: "inline-block", duration: 2, ease: Power1.easeInOut})
      tl.current.to([wordForAnimOrg, wordForAnimBenefit], {autoAlpha: 0, display: "none", duration: 1, ease: Power1.easeInOut})
    })
    tl.current.play();
    
    return() => tl.current.pause().kill(); 
  }, [])
  

  const AnimText = () => {
    if(text){
      return(
        <div className="org py-3">
          {wordsForAnimation.map((word, index) => {
            return <span key={`animated-org-${index}`} className="animated-text animated-text-org px-1">{word}</span>
          })}
        </div>
      )
    } else {
      return(
        <div className="inline-block">
        {wordsForAnimation.map((word, index) => {
          return <span key={`animated-benefit-${index}`} className=" animated-text animated-text-benefit px-1">{word}</span>
        })}
      </div>
      )
    }
      
  }

  return(
   <AnimText />
  )
}

export default AnimatedText;