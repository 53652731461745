import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Logos from "../components/Logos"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import BannerReverse from "../components/Banner_reverse"
import Banner from "../components/Banner"
import BenefitsWrap from "../components/BenefitsWrap"
import Organization from "../components/Organization"
import FaqWrapper from "../components/FaqWrapper"
import highfive from "../images/highfive.svg"
import magglass from "../images/magglass.svg"
import ContactBanner from "../components/ContactBanner"
import YoutubeVideo from "../components/YoutubeVideo"

const IndexPage = ({ data }) => {
  const bannerData = data.bannerData.edges
  return (
    <Layout>
      <SiteMetadata
        title="Zurly Digital Fundraising and Membership Cards"
        description="Homepage"
      />

      <Hero
        heading={data.hero.h1}
        image={data.hero.image.localFile.childImageSharp.fluid}
        altImage={data.hero.image.description}
        buttonText={data.hero.buttonText}
        buttonLink={data.hero.buttonLink}
        smallCaps={data.hero.smallCaps}
        wordsForAnimation={data.hero.wordsForAnimation}
        wordsForAnimation2={data.hero.wordsForAnimation2}
        screenshots={data.screenshots}
      />
      <YoutubeVideo />
      <Logos logoText={data.logos.headliningText} logos={data.logos.logo} />

      <BenefitsWrap
        title={data.benefits.h1}
        buttonLink={data.benefits.buttonLink}
        buttonText={data.benefits.buttonText}
        cards={data.benefits.card}
      />
      <div className="flex flex-col items-center px-4 pt-20 pb-64 text-center bg-herobg md:px-6 lg:px-8">
        <h1 className="text-center">
          Create your new virtual pass or card with ease
        </h1>
        <Banner
          sequence={bannerData[0].node.sequence}
          headline={bannerData[0].node.title}
          copy={bannerData[0].node.description.description}
          cta_link={bannerData[0].node.ctaLink}
          cta_title={bannerData[0].node.ctaTitle}
          image={bannerData[0].node.bannerImage.localFile.childImageSharp.fluid}
          image_title={bannerData[0].node.bannerImage.title}
        />
        <BannerReverse
          sequence={bannerData[1].node.sequence}
          headline={bannerData[1].node.title}
          copy={bannerData[1].node.description.description}
          cta_link={bannerData[1].node.ctaLink}
          cta_title={bannerData[1].node.ctaTitle}
          image={bannerData[1].node.bannerImage.localFile.childImageSharp.fluid}
          image_title={bannerData[1].node.bannerImage.title}
        />
        <Banner
          sequence={bannerData[2].node.sequence}
          headline={bannerData[2].node.title}
          copy={bannerData[2].node.description.description}
          cta_link={bannerData[2].node.ctaLink}
          cta_title={bannerData[2].node.ctaTitle}
          image={bannerData[2].node.bannerImage.localFile.childImageSharp.fluid}
          image_title={bannerData[2].node.bannerImage.title}
        />
      </div>
      <div className="relative">
        <div className="floating-image">
          <img src={highfive} alt="teamwork graphic" />
        </div>
      </div>
      <Organization data={data.org} />
      <div className="relative">
        <div className="floating-image">
          <img src={magglass} alt="magnifying glass graphic" />
        </div>
      </div>
      <div className="pt-20 bg-customlightgray">
        {data.resources.edges && data.resources.edges.length > 0 ? (
          <Cards items={data.resources.edges} />
        ) : (
          <></>
        )}
      </div>
      <FaqWrapper faqs={data.faqs} />
      <ContactBanner />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    resources: allContentfulBlogPost(
      filter: { tags: { eq: "home" } }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          title
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          featuredImage {
            fluid {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
    hero: contentfulLandingHero {
      buttonText
      buttonLink
      image {
        description
        localFile {
          childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      smallCaps
      wordsForAnimation
      wordsForAnimation2
    }
    screenshots: contentfulHeroRotatingScreenshots {
      frame {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      image1 {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      image2 {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      image3 {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      image4 {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      image5 {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      backgroundImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
    logos: contentfulLogos {
      logo {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
      }
      headliningText
    }
    bannerData: allContentfulHomeBanner(
      sort: { order: ASC, fields: sequence }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          title
          ctaLink
          ctaTitle
          sequence
          description {
            description
          }
          bannerImage {
            title
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    benefits: contentfulCardWrapper(node_locale: { eq: "en-US" }) {
      h1
      buttonText
      buttonLink
      card {
        featureBenefits
        featureTitle
        icon {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          description
        }
      }
    }
    org: contentfulForYourOrganization {
      button
      buttonLink
      feature1
      feature1Link
      feature2
      feature2Link
      feature3
      feature3Link
      h1
      subheader
    }
    faqs: allContentfulFaqs(sort: { fields: order }) {
      nodes {
        id
        order
        answer {
          answer
        }
        question {
          question
        }
      }
    }
  }
`
